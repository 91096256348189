<template>
  <v-card
    v-bind="$attrs"
    class="dashcard ma-2"
    :height="cardHeight"
    elevation="3"
    :id="card.slug"
  >
    <v-overlay absolute :opacity="0.5" :value="loading">
      <v-progress-circular color="primary" indeterminate size="100">
        <v-avatar tile size="50">
          <v-img
            :lazy-src="settings.loader"
            contain
            :src="settings.squareLogo"
          ></v-img>
        </v-avatar>
      </v-progress-circular>
    </v-overlay>
    <v-card-title class="card-header" v-if="hasHeader">
      <v-row dense no-gutters align="center" justify="start">
        <slot name="header-prepend"></slot>
        <span class="title primary--text"> {{ card.title }} </span>
        <slot name="header-left"></slot>
        <v-spacer></v-spacer>
        <slot name="header-right"></slot>
        <div class="card-button-wrapper">
          <v-hover v-slot:default="{ hover }" v-if="hasExpand">
            <v-icon class="icon" @click="expandCard" right>
              {{ hover ? 'mdi-arrow-expand-all' : 'mdi-arrow-expand' }}
            </v-icon>
          </v-hover>
          <v-hover v-slot:default="{ hover }" v-if="hasDelete">
            <v-icon class="icon" @click="deleteCard" right>
              {{ hover ? 'mdi-close-circle-outline' : 'mdi-close' }}
            </v-icon>
          </v-hover>
        </div>
      </v-row>
    </v-card-title>
    <v-card-text class="card-body">
      <slot name="default">
        <div class="content">
          <slot name="toolbar">
            <v-toolbar flat color="transparent" dense class="ytoolbar mb-0">
              <v-tabs
                v-model="tab"
                class="tab-header"
                @change="$emit('change-tab', tab)"
              >
                <v-tab
                  flat
                  class="text-none tab-item"
                  v-for="(item, index) in tabs"
                  :key="index"
                  :href="`#${item.slug}`"
                >
                  {{ item.title }}
                </v-tab>
              </v-tabs>
            </v-toolbar>
          </slot>
          <v-row dense align="center" class="d-flex pa-1 justify-space-around">
            <v-col cols="12" md="8">
              <v-tabs-items v-model="tab">
                <v-tab-item value="month">
                  <v-card flat>
                    <v-card-text
                      class="d-flex fullwidth align-center justify-center"
                    >
                      <slot name="month"> Provide content using:month </slot>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item value="week">
                  <v-card flat>
                    <v-card-text
                      class="d-flex fullwidth align-center justify-center"
                    >
                      <slot name="week"> Provide content using slot:week </slot>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item value="today">
                  <v-card flat>
                    <v-card-text
                      class="d-flex fullwidth align-center justify-center"
                    >
                      <slot name="today">
                        Provide content using slot:today
                      </slot>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
            <v-col
              cols="12"
              md="4"
              class="d-flex align-center justify-center flex-column overflow-hidden"
            >
              <slot name="above-image"></slot>
              <v-avatar
                height="150"
                class="pa-1"
                tile
                size="100%"
                style="overflow: hidden !important"
              >
                <v-img
                  :lazy-src="settings.loader"
                  contain
                  :src="card.image"
                ></v-img>
              </v-avatar>
            </v-col>
          </v-row>
        </div>
      </slot>
    </v-card-text>
    <slot name="card-footer"></slot>
  </v-card>
</template>
<script>
export default {
  inheritAttrs: false,
  name: 'Dashcard',
  props: {
    hasHeader: { type: Boolean, default: true },
    hasExpand: { type: Boolean, default: true },
    hasDelete: { type: Boolean, default: true },
    cardHeight: { type: Number, default: 300 },
    loading: { type: Boolean, default: true },
    card: { type: Object }
  },
  data: () => ({
    tab: 'month',
    tabs: [
      { title: 'This Month', slug: 'month' },
      { title: 'This Week', slug: 'week' },
      { title: 'Today', slug: 'today' }
    ]
  }),
  methods: {
    deleteCard() {
      this.$emit('delete-card', this.card)
    },
    expandCard() {
      this.$emit('expand-card', this.card)
    }
  }
}
</script>
<style lang="scss" scoped>
.dashcard {
  min-height: 320px !important;
  border-radius: 15px !important;
  .card-header {
    height: 50px;
    .title {
      font-weight: bolder;
      color: #223a6b;
    }
    .card-button-wrapper {
      position: absolute;
      top: 5px;
      right: 10px;
      .icon {
        font-weight: bolder;
        font-size: 20px;
        color: #223a6b;
      }
    }
  }
  .card-body {
    overflow: hidden !important;
    padding: 0px;
    .tab-header {
      border-bottom: 1px solid #e0e0e0;
      .tab-item {
        color: #8092b8;
        &.v-tab--active {
          color: $blue;
          font-weight: bolder;
        }
      }
    }
  }
}
</style>
